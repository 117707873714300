import './CoffeeMobileLandingPage.css';
import React, { useState,useEffect } from 'react';
import Modal from '../../components/modalComponent';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import TermsOfUse from '../../components/TermsOfUse';
import { Link,useLocation,useNavigate } from 'react-router-dom';

const LandingPageMobile = () => {
  const [emailBoxTop, setEmailBoxTop] = useState("")
  const [buttonTextTop, setButtonTextTop] = useState('Sign Up');
  const [buttonTextBottom, setButtonTextBottom] = useState('Sign Up');
  const location = useLocation(); // Get the current location
  const navigate = useNavigate()

  const [emailBoxBottom, setEmailBoxBottom] = useState("")


  // State to handle modal visibility
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleClick = (setButtonText) => {
    setButtonText('✔️'); // Set to tick mark
    setTimeout(() => {
      setButtonText('Sign Up'); // Set back to "Sign Up" after 2 seconds
    }, 2000);
  };
  const handleOpenModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };
  const clearQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(param);

    // Update the URL without the specified query parameter
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    }, { replace: true });
  };
  // Check the URL for the query parameter or path and open the modal
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const modal = searchParams.get('doc'); // Check for ?modal=privacy

    if (modal !== null){
      handleOpenModal(modal)
      }
  }, [location]);

  return (
    <div className="landing-page-mobile">
      <div className="div">
        <div className="child" />
        <div className="header">
          <div />
          <div className="logo-wrap-parent">
            <div className="logo-wrap">
              <div className="logomark">
                <img className="logomark-child" alt="" src="coffee-logo.svg" />
              </div>
              <div className="logotype">
                <h3 className="coffee">coffee</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="coffee-an-ios-exclusive-datin-parent">
            <h1 className="coffee-an-ios">{`Coffee: An iOS exclusive dating app built for India. `}</h1>
            <div className="frame-parent">
              <div className="rectangle-parent">
                <img className="rectangle-shadow" alt="" src="peach-shadow.svg" />
                <img className="rectangle-icon" alt="" src="mobile-page-rectangle-left.svg" />
                <img className="rectangle-icon1" alt="" src="mobile-page-rectangle-right.svg" />
              </div>
              <div className="because-less-is-more-parent">
                <h1 className="because-less-is">{`Because Less Is More. `}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="email-capture">
        <div className="container1">
          <div className="email-capture1">
            <div className="input-field">
              <label htmlFor="emailInput" className="label">Email</label>
              <input
                id="emailInput"
                type="email"
                className="input"
                value={emailBoxTop}
                onChange={(e) => setEmailBoxTop(e.value)}
                placeholder="Enter your email"
                required
              />
              <div className="hint-text">We will notify you once we are live!</div>
            </div>
            <button type="button" className="button1" data-text="Sign Up" onClick={() => {
              setEmailBoxBottom("")
              handleClick(setButtonTextTop)
            }}>
              <div className="placeholder" />
              <div className="text">{buttonTextTop}</div>
              <div className="placeholder" />
            </button>

          </div>
        </div>
      </div>
      <div className="div1">
        <div className="container2">
          <div className="indian-dating-apps-are-clutter-parent">
            <h1 className="indian-dating-apps">Indian Dating apps are cluttered with spam and low quality profiles.</h1>
            <div className="d">
              <div className="frame-group">
                <div className="discover-100-verified-profile-wrapper">
                  <h1 className="discover-100-verified">Discover 100% verified profiles on Coffee. Because you deserve better.</h1>
                </div>
                <img className="group-inner" alt="" src="verifed-check.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div1">
        <div className="container3">
          <h1 className="access-your-analytics">Access your analytics to learn how to make your profile stand out.</h1>
          <div className="frame-container">
            <img className="" alt="" src="mobile-analytics.svg" />
          </div>
        </div>
      </div>
      <div className="div1">
        <div className="container4">
          <h1 className="go-beyond-a-container">
            <p className="impression-factor-of">{`Go Beyond a Swipe: Rate Profiles as `}</p>
            <p className="impression-factor-of">
              <span className="red">Red</span>
              <span className="span">{` & `}</span>
              <span className="green">Green</span>
              <span> Flags Anonymously!</span>
            </p>
          </h1>
          <div className="demo">
            <div className="demo-parent">
              <img className="demo-icon" alt="" src="mobile-mobile.png" />
              <div className="frame-parent2">
                <div className="save-flag-wrapper">
                  <div className="save-flag">Save Flag</div>
                </div>
                <div className="green-flag-parent">
                  <div className="green-flag">Green Flag</div>
                  <img className="solarflag-bold-duotone-icon2" alt="" src="mobile-green.svg" />
                </div>
                <div className="red-flag-parent">
                  <div className="green-flag">Red Flag</div>
                  <img className="solarflag-bold-duotone-icon2" alt="" src="mobile-red.svg" />
                </div>
                <div className="this-rating-is">{`This rating is anonymous & independent of other actions`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="email-capture2">
        <div className="container5">
          <div className="heading-and-supporting-text">
            <h2 className="heading">We are here to spill the beans</h2>
            <div className="supporting-text">Stay up to date with the latest news and announcements about Coffee.</div>
          </div>
          <div className="email-capture3">
            <div className="input-field">
              <label htmlFor="emailInput" className="label">Email</label>
              <input
                id="emailInput"
                type="email"
                className="input"
                placeholder="Enter your email"
                required
                value={emailBoxBottom}
                onChange={(e) => setEmailBoxBottom(e.value)}
              />
              {/* <div className="hint-text">We will notify you once we are live!</div> */}
            </div>
            <button type="button" className="button1" data-text={buttonTextBottom} onClick={() => {
              setEmailBoxBottom("")
              handleClick(setButtonTextBottom)
            }}>
              <div className="placeholder" />
              <div className="text">{buttonTextBottom}</div>
              <div className="placeholder" />
            </button>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="frame-parent3">
          <div className="frame-parent4">
            <div className="logo-parent">
              <div className="logo">
                <div className="logo-wrap">
                  <div className="logotype">
                    <h3 className="heading1">coffee</h3>
                  </div>
                  <div className="logomark1">
                    <img className="logomark-child" alt="" src="coffee-logo.svg" />
                  </div>
                </div>
              </div>
              <div className="supporting-text1">Because Less is More</div>
            </div>
            <div className="actions">
            <a href="https://apps.apple.com/in/app/coffee-brew-connections/id6478821053" target="_blank" rel="noopener noreferrer">
            <img className="mobile-app-store-badge" alt="" src="app-store.svg" />
              </a>

            </div>
          </div>
          <div className="footer-text-parent">
            <div className="footer-text">© FREETAIL TECHNOLOGIES PRIVATE LIMITED.</div>
            <div className="terms-privacy-links">
            <Link to="/?doc=terms">Terms</Link>
            <Link to="/?doc=privacy">Privacy</Link>
              <Link to={"/support"}>Help</Link>
            </div>
            <div className="social-icons">
              <div className="social-icon" />
              <a href="https://x.com/the_coffeeapp?s=11" target="_blank" rel="noopener noreferrer">
                <img className="social-icon" alt="Twitter" src="twitter.svg" />
              </a>
              <a href="https://www.linkedin.com/company/thecoffeeapp/" target="_blank" rel="noopener noreferrer">
                <img className="social-icon" alt="LinkedIn" src="linkedin.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onClose={() => {setShowModal(false)
        clearQueryParam("doc")
      }}>
        {modalContent === "terms" ? (
          <TermsOfUse />
        ) : <PrivacyPolicy />}
      </Modal>
    </div>);
};

export default LandingPageMobile;
