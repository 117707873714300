import './CoffeeDesktopLandingPage.css';
import React, { useState,useEffect } from 'react';
import Modal from '../../components/modalComponent';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import TermsOfUse from '../../components/TermsOfUse';
import { Link,useLocation,useNavigate } from 'react-router-dom';
const CoffeeLandingPage = () => {
  const navigate = useNavigate()
  const [emailBoxTop, setEmailBoxTop] = useState("");
  const [buttonTextTop, setButtonTextTop] = useState('Sign Up');
  const [buttonTextBottom, setButtonTextBottom] = useState('Sign Up');
  const [emailBoxBottom, setEmailBoxBottom] = useState("");
  const location = useLocation(); // Get the current location
  // State to handle modal visibility
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleClick = (setButtonText,email) => {
    setButtonText('✔️'); // Set to tick mark
    sendDataToGoogleSheets(email);
    setTimeout(() => {
      setButtonText('Sign Up'); // Set back to "Sign Up" after 2 seconds
    }, 2000);
  };
  // const handleOpenModal = (content) => {
  //   setModalContent(content);
  //   setShowModal(true);
  // };

  // Check the URL for the query parameter or path and open the modal
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const modal = searchParams.get('doc'); // Check for ?modal=privacy
    console.log(modal)
    if (modal !== null){
    handleOpenModal(modal)
    }// if (modal === 'privacy') {
    //   handleOpenModal("privacy");
    // }
  }, [location]);

  const handleOpenModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const GOOGLE_SHEET_URL = "https://script.google.com/macros/s/AKfycbwRt-vxBs6oezm3JgTyKndB73ZLAW9TZEfdM0wDsoCwj7SkWvzUaKpWFKifJWm2iZbGaQ/exec";

  const sendDataToGoogleSheets = async (email) => {
    try {
      const response = await fetch(GOOGLE_SHEET_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode:"no-cors",
        body: JSON.stringify({ email : email }),
      });
  
      // Ensure that the response is correctly parsed
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log('Success:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const clearQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(param);

    // Update the URL without the specified query parameter
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    }, { replace: true });
  };
  return (
    <div className="coffee-landing-page-desktop">
      <div className="container-wrapper-desktop">
        <div className="container-desktop">
          <div className="content-desktop">
            <div className="logo-desktop">
              <div className="logo-wrap-desktop">
                <div className="logomark-desktop">
                  <img className="logomark-child-desktop" alt="" src="coffee-logo.svg" />
                </div>
                <div className="logotype-desktop">
                  <h3 className="coffee-desktop">coffee</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div-desktop">
        <div className="frame-parent-desktop">
          <div className="frame-group-desktop">
            <div className="coffee-an-ios-exclusive-datin-parent-desktop">
              <h1 className="coffee-an-ios-desktop">{`Coffee: An iOS exclusive dating app built for India. `}</h1>
              <div className="o-desktop">
                <div className="because-less-is-more-parent-desktop">
                  <h1 className="because-less-is-desktop">{`Because Less Is More. `}</h1>
                  {/* <img className="group-child" alt="" src="Vector 293.svg" />
                  <img className="group-item" alt="" src="Vector 294.svg" /> */}
                </div>
              </div>
            </div>
            <div className="email-capture-desktop">
              <div className="input-field-desktop">
                <label htmlFor="emailInput" className="label">Email</label>
                <input
                  id="emailInput"
                  type="email"
                  className="input-desktop"
                  placeholder="Enter your email"
                  value={emailBoxTop}
                  onChange={(e) => { setEmailBoxTop(e.value) }}
                  required
                />
                <div className="hint-text-desktop">We will notify you once we are live!</div>
              </div>
              <button type="button" className="button3-desktop" data-text={buttonTextTop} onClick={() => {
                handleClick(setButtonTextTop,emailBoxTop)
                setEmailBoxTop("")

              }}>
                <div className="placeholder" />
                <div className="text-desktop">{buttonTextTop}</div>
                <div className="placeholder" />
              </button>
            </div>
          </div>
          <div className="frame-wrapper-desktop">
            <div className="group-wrapper-desktop">
              <div className="group-wrapper-desktop">
                <div className="group-inner" />
                <img className="rectangle-icon1-desktop" alt="" src="mobile-left-desktop.svg" />
                <img className="rectangle-icon-desktop" alt="" src="mobile-right-desktop.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div1-desktop">
        <div className="frame-container-desktop">
          <div className="indian-dating-apps-are-clutter-wrapper-desktop">
            <h1 className="indian-dating-apps-desktop">Indian Dating apps are cluttered with spam and low quality profiles.</h1>
          </div>
          <div className="group-container-desktop">
            <div className="group-div-desktop">
              <img className="frame-icon" alt="" src="verified-desktop.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className="div2-desktop">
        <div className="access-your-analytics-to-learn-parent-desktop">
          <h1 className="access-your-analytics-desktop">Access your analytics to learn how to make your profile stand out.</h1>
          <div className="rectangle-group-desktop">
            <div className="rectangle-div" />
            <img className="" alt="" src="analtics-desktop.svg" />
          </div>
        </div>
      </div>
      <div className="div9-desktop">
        <div className="group-parent-desktop">
          <div className="iphone-15-blue-parent-desktop">
            <img className="iphone-15-blue" alt="" src="mobile-desktop.png" />
            <div className="frame-parent1-desktop">
              <img className="zoom-in-desktop" alt="" src="flag-zoom-desktop.svg" />
              {/* <div className="save-flag-wrapper">
                  <div className="save-flag">Save Flag</div>
                </div>
                <div className="green-flag-parent">
                  <div className="green-flag">Green Flag</div>
                  <img className="solarflag-bold-duotone-icon2" alt="" src="mobile-green.svg" />
                </div>
                <div className="red-flag-parent">
                  <div className="green-flag">Red Flag</div>
                  <img className="solarflag-bold-duotone-icon2" alt="" src="mobile-red.svg" />
                </div>
                <div className="this-rating-is">{`This rating is anonymous & independent of other actions`}</div> */}
            </div>
          </div>
          <h1 className="go-beyond-a-container-desktop">
            <span>{`Go Beyond a Swipe: Rate Profiles as `}</span>
            <span className="red-desktop">Red</span>
            <span>{` and `}</span>
            <span className="green-desktop">Green</span>
            <span> Flags Anonymously!</span>
          </h1>
        </div>
      </div>
      <div className="footer-desktop">
        <div className="section-desktop">
          <div className="container1-desktop">
            <div className="content2-desktop">
              <div className="heading-and-supporting-text-desktop">
                <h2 className="heading-desktop">We are here to spill the beans</h2>
                <div className="supporting-text-desktop">Stay up to date with the latest news and announcements about Coffee.</div>
              </div>
              <div className="email-capture1-desktop">
                <div className="logo-and-links-desktop">
                  <div className="input-field-desktop">
                    <label htmlFor="emailInput" className="label">Email</label>
                    <input
                      id="emailInput"
                      type="email"
                      className="input-desktop"
                      placeholder="Enter your email"
                      required
                      value={emailBoxBottom}
                      onChange={(e)=>{setEmailBoxBottom(e.value)}}
                    />
                  </div>
                </div>
                <button type="button" className="button4-desktop" data-text={buttonTextBottom} onClick={() => {
                  setEmailBoxBottom("")
                  handleClick(setButtonTextBottom,emailBoxBottom)
                }}>
                  <div className="placeholder" />
                  <div className="text-desktop">{buttonTextBottom}</div>
                  <div className="placeholder" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container2-desktop">
          <div className="content4-desktop">
            <div className="logo-and-links-desktop">
              <div className="logo-and-supporting-text-desktop">
                <div className="logo-desktop">
                  <div className="logo-wrap-desktop">
                    <div className="logotype-desktop">
                      <h3 className="coffee-desktop">coffee</h3>
                    </div>
                    <div className="logomark1-desktop">
                      <img className="logomark1-child" alt="" src="coffee-logo.svg" />
                    </div>
                  </div>
                </div>
                <div className="supporting-text1-desktop">Because Less is More</div>
              </div>
            </div>
            <div className="app-store-actions-desktop">
              {/* <div className="heading2-desktop">(Coming Soon)</div> */}
              <div className="actions-desktop">
              <a href="https://apps.apple.com/in/app/coffee-brew-connections/id6478821053" target="_blank" rel="noopener noreferrer">
            <img className="mobile-app-store-badge" alt="" src="app-store.svg" />
              </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container3-desktop">
          <div className="content5-desktop">
            <div className="footer-text-desktop">© 2024 FREETAIL TECHNOLOGIES PRIVATE LIMITED. All rights reserved.</div>
            <div className="terms-privacy-links">
              {/* <a href="#" onClick={() => handleOpenModal("terms")}>Terms</a> */}
              <Link to="/?doc=terms">Terms</Link>
              <Link to="/?doc=privacy">Privacy</Link>
              {/* <a href="#" onClick={() => handleOpenModal("privacy")}>Privacy</a> */}
              <Link to={"/support"}>Help</Link>
            </div>
            <div className="social-icons-desktop">
              <div className="social-icon-desktop" />
              <a href="https://x.com/the_coffeeapp?s=11" target="_blank" rel="noopener noreferrer">
                <img className="social-icon-desktop" alt="Twitter" src="twitter.svg" />
              </a>
              <a href="https://www.linkedin.com/company/thecoffeeapp/" target="_blank" rel="noopener noreferrer">
                <img className="social-icon-desktop" alt="LinkedIn" src="linkedin.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Implementation */}
      <Modal show={showModal} onClose={() => {
        setShowModal(false)
        clearQueryParam("doc")}}>
        {modalContent === "terms" ? (
          <TermsOfUse />
        ) : <PrivacyPolicy/>}
      </Modal>
    </div>
  );
};

export default CoffeeLandingPage;
